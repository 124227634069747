.modalContainer {
    position: absolute;
    background: var(--white-color);
    overflow: auto;
    outline: none;
    padding: 16px;
    border: none;
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    inset: 0;
    width: 100%;
}

.withTransparency {
    padding-top: 32px;
    top: 64px;
    border-radius: 12px 12px 0 0 !important;
}

.withCustomModalContainer {
    background: var(--white-color);
    overflow: auto;
    outline: none;
    padding: 16px;
    border: none;
    border-radius: 0;
    width: 100%;
    max-width: 440px;
    display: inline-block;
    line-height: normal;
    position: relative;
    vertical-align: middle;
    min-height: 100%;
}

.defaultOverlay {
    position: fixed;
    overflow: auto;
    z-index: var(--z-index-overlay);
    inset: 0;
    background-color: rgb(0 0 0 / 50%);
    transition: 0.3s;
}

.customOverlay {
    overflow: auto;
    z-index: var(--z-index-overlay);
    background-color: rgb(0 0 0 / 50%);
    transition: 0.3s;
    position: fixed;
    width: 100vw;
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    top: 0;
    left: 0;
}

.networkModalOverlay {
    position: fixed;
    overflow: auto;
    z-index: var(--z-index-overlay);
    inset: 0;
    background-color: rgb(0 0 0 / 50%);
    transition: 0.3s;
}

.overlayWithOpen {
    opacity: 0;
    transform: translate(0, 20px);
    visibility: hidden;
}

.overlayWithClose {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
}

.overlayWithTransparency {
    background: rgb(0 0 0 / 50%) !important;
}

.centeredModal {
    background-color: var(--surface-default);
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 343px;
    border-radius: var(--default-border-radius);
    z-index: var(--z-index-maxOverlay);
}

.withExclusiveOffer {
    padding: 0 !important;
    width: 100% !important;
}

.withFlexbox {
    display: flex;
    flex-direction: column;
}

.withExitPopup {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-default);
}

.modalContainer__withOnTimer {
    padding-top: 76px;
}

.modalContainer__withOffTimer {
    padding-top: 44px;
}

.timer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--critical-default);
    padding: 8px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.timerEnd {
    display: none;
}

.timer span {
    margin-right: 5px;
}

.withUpsellSubscription {
    top: 32px;
    border: none;
}

@media screen and (min-width: 601px) {
    .desktopStyles {
        top: 50%;
        right: unset;
        bottom: unset;
        left: 50%;
        width: 432px;
        border-radius: 12px !important;
        transform: translate(-50%, -50%);
    }

    .withFixedWidth {
        width: 432px;
    }

    .withCustomModalContainer {
        min-height: initial;
        border-radius: 12px;
    }

    .withUpsellSubscription {
        border: 1px solid var(--border-default);
    }
}

.withFunnelRedesign * {
    font-family: Inter, serif !important;
}